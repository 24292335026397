import Chart from 'chart.js/auto'

import "./vendor/import-jquery";

import '../node_modules/leaflet/dist/leaflet.css';
import 'leaflet';

import canvasOverlay from './vendor/L.CanvasOverlay';

import iconRetinaUrl from '../node_modules/leaflet/dist/images/marker-icon-2x.png';
import iconUrl from '../node_modules/leaflet/dist/images/marker-icon.png';
import shadowUrl from '../node_modules/leaflet/dist/images/marker-shadow.png';

// Assign the imported image assets before you do anything with Leaflet.
L.Marker.prototype.options.icon = L.icon({
	iconRetinaUrl,
	iconUrl,
	shadowUrl,
	iconSize: [25, 41],
	iconAnchor: [12, 41],
	popupAnchor: [1, -34],
	tooltipAnchor: [16, -28],
	shadowSize: [41, 41],
});


$(function () {
	var from = null;
	var interval = -1;
	var lastFetchDate = null;

	var lightningAnimFrame = 0;
	var displayedLightnings = 0;
	var lightnings = [];
	var animatinglightnings = [];
	var lightningCanvasLayer = canvasOverlay();

	var $fenceTooltip = $(".fence-tooltip");
	var fence_tooltip_visible = false;

	var radarStart = [50.51571417076662, 13.507735203955168];
	var radarEnd = [43.98740952591053, 25.483569647228816];
	var radarImageOverlayBounds = [radarStart, radarEnd];
	var actualRadar = null;
	var radarLayer = null;

	var highlighted_fence = null;
	var radarFences = [];
	var radarFencePaths = [];
	var radarFenceForecasts = [];
	var radarFenceLayer = canvasOverlay();

	var pickedPt = null;
	var closestFence = null;
	var smallestDiff = {dist: 10000, pt: null};

	var closestRadarDistance = -1;
	var closestRadarDirection = -1;

	var closestLightningDistance = -1;
	var closestLightningDirection = -1;
	var $circle = $(".warning-circle");

	var $chart = $(".chart");

	var omsz = null;
	var $omszBox = $(".trisonica ul");


	let chart1, chart2;

	var map = L.map('map', {
		center: [46.96,17.45],
		zoom: 12,
		layers: [
			L.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}', {
				attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
				maxZoom: 18,
				id: 'mapbox/dark-v10',
				tileSize: 512,
				zoomOffset: -1,
				accessToken: 'pk.eyJ1Ijoic3phYnRvIiwiYSI6ImNrenh6dWluMzA2a2Uyb21vNjh3MWI2b3UifQ.MB9b9qij0gq5JmLUso7PfA'
			}),
			lightningCanvasLayer,
			radarFenceLayer
		]
	});
	L.control.scale({
		imperial: false
	}).addTo(map);


	var closestIsOverlay = false;

	var race = [46.96,17.45];
	pickedPt = {
		lat: race[0],
		lng: race[1]
	};

	var raceMarker = new L.Marker(race);
	raceMarker.addTo(map);

	const airspace = [
		[46.936944, 17.461667],
		[46.952222, 17.435],
		[46.974167, 17.453889],
		[46.978889, 17.461111],
		[46.967222, 17.488611],
		[46.950278, 17.472778],
		[46.936944, 17.461667]
	];
	L.polygon(airspace, {
		fill: true,
		stroke: true,
		color: '#ff8133',
		weight: 103,
		fillOpacity: 0.5,

		opacity: 1
	}).addTo(map);

	const coords = [[46.95656134423069,17.45703192786661],
		[46.96962593174115, 17.46865447254662],
		[46.96775458283506, 17.46123113779687],
		[46.96423140262411, 17.46966583464589],
		[46.96959354188628, 17.46868141187129]];

	L.polygon(coords).addTo(map);

	const coords2 = [
		[46.95151973977598, 17.43452239939998],
		[46.94239726129693, 17.45858810483388],
		[46.96675581647376, 17.47854770123928],
		[46.97506840801196, 17.45562819686073],
		[46.95151973977598, 17.43452239939998]
	];
	//L.polygon(coords2, {color: 'red', stroke: true}).addTo(map);

	var lightningCanvas = lightningCanvasLayer.canvas();
	var lightningContext = lightningCanvas.getContext("2d");
	lightningCanvasLayer.drawing(drawLightning);


	var radarFenceCanvas = radarFenceLayer.canvas();
	var radarFenceContext = radarFenceCanvas.getContext("2d");
	radarFenceLayer.drawing(drawRadarFence);

	map.on("mousemove", function (e) {
		var pt = e.containerPoint;

		var fence = null;
		for (var i in radarFencePaths) {
			if (radarFenceContext.isPointInPath(radarFencePaths[i], pt.x, pt.y)) {
				fence = radarFencePaths[i].fence_details;
				break;
			}
		}

		/*if (!fence && highlighted_fence !== null) {
			highlighted_fence = null;
			$fenceTooltip.css("display", "none");
			fence_tooltip_visible = false;

			radarFenceLayer.redraw();
		} else if (fence && highlighted_fence !== fence) {
			highlighted_fence = fence;

			$fenceTooltip.css("display", "block");

			$.each($fenceTooltip.find("b"), function() {
				var prop = $(this).attr("data-property");
				if (fence.hasOwnProperty(prop)) {
					$(this).html(fence[prop]);
				}
			});
			fence_tooltip_visible = true;
			radarFenceLayer.redraw();
		}*/

		if (fence_tooltip_visible) {
			var point = {};
			if (pt.x + 250 > window.innerWidth) {
				point.left = (pt.x - 250) + "px";
			} else {
				point.left = (pt.x + 20) + "px";
			}

			if (pt.y + 110 > window.innerHeight) {
				point.top = (pt.y - 110) + "px";
			} else {
				point.top = (pt.y + 20) + "px";
			}
			$fenceTooltip.css("left", point.left).css("top", point.top);
		}
	});

	map.on("zoom", function() {
		generateRadarFencePaths();
	});
	map.on("move", function() {
		smallestDiff = {dist: 10000, pt: null};
		generateRadarFencePaths();
	});

	$(".info-button").mouseover(function() {
		$(".info .img").css("display", "");
	}).mouseout(function() {
		$(".info .img").css("display", "none");
	});

	var ww = $(window).width(),
		wh = $(window).height();

	$(".info .img img").css({
		"max-width": (ww - 200) + "px",
		"max-height": (wh - 200) + "px"
	});

	var distanceMarker = null;

	/*map.on("click", function(e) {
		pickedPt = [e.latlng.lat, e.latlng.lng];
		if (distanceMarker !== null) {
			distanceMarker.setLatLng(pickedPt);
		} else {
			distanceMarker = L.marker(pickedPt).addTo(map);
		}
		pickedPt = [e.latlng.lat, e.latlng.lng];
		smallestDiff = {dist: 10000, pt: null};
		generateRadarFencePaths();
		radarFenceLayer.redraw();
	});*/


	fetchLightnings();


	getKpIndex((data) => {
		const $button = $(".kp-index .button");


		const val = Math.round(data.measured * 10) / 10;
		$button.find(".value").html(val);
		$button.addClass("level-"+val);
	});

	$chart.mouseover(function() {
		var totalWidth = $("#map").width();
		if (totalWidth > 1100) {
			$chart.css("width", (totalWidth - (totalWidth - 640 + 30) / 2) + "px");
		} else {
			$chart.css("width", totalWidth + "px");
		}
		$chart.addClass("hovered");
	}).mouseout(function() {
		$chart.removeClass("hovered");
		if (!$chart.hasClass("hover-fixed")) {
			$chart.css("width", "");
		}
	}).click(function() {
		$chart.toggleClass("hover-fixed");
	});

	function drawLightning() {
		console.log("redrawing lightning canvas");

		lightningContext.clearRect(0, 0, lightningCanvas.width, lightningCanvas.height);

		var time = new Date().getTime();
		var bounds = map.getBounds();
		var zoom = map.getZoom();
		if (lightnings) {
			var list = Object.values(lightnings);

			var filtered = list.filter(x => bounds.contains([x.location.lat, x.location.lon]));
			displayedLightnings = filtered.length;
			updateLastUpdatedText();

			$.each(filtered, function (a) {
				var ts = this.timestamp.unix;
				var diff = (time - ts) / 1000;
				var pt = map.latLngToContainerPoint([this.location.lat, this.location.lon]);
				lightningContext.fillStyle = "rgb(255, " + (255 - (255 * (diff / 600))) + ", 0)";
				if (zoom >= 12) {
					var fontSize = this.height > 0 ? 24 : 48;
					lightningContext.font = (fontSize > 24 ? 'bold ' : '') + fontSize + "px sans-serif";
					lightningContext.fillText(this.strength < 0 ? "-" : "+", pt.x - (fontSize * 0.3), pt.y + (fontSize * 0.2));
				} else {
					lightningContext.beginPath();
					lightningContext.arc(pt.x, pt.y, zoom >= 12 ? 10 : (zoom >= 10 ? 5 : 1), 0, 2 * Math.PI);
					lightningContext.fill();
				}
			});
		}

		if (animatinglightnings.length > 0) {
			for (var i in animatinglightnings) {
				var pt = animatinglightnings[i];
				var loc = map.latLngToContainerPoint([pt[1].location.lat, pt[1].location.lon]);
				pt[2]++;

				lightningContext.fillStyle = "rgb(255, " + (255 * (lightningAnimFrame / 29)) + ", 0)";
				lightningContext.beginPath();
				lightningContext.arc(loc.x, loc.y, (30 - lightningAnimFrame) * 0.5, 0, 2 * Math.PI);
				lightningContext.fill();
			}


			lightningAnimFrame++;
			if (lightningAnimFrame < 30) {
				L.Util.requestAnimFrame(drawLightning, map);
			} else {
				lightningAnimFrame = 0;
				for (var i in animatinglightnings) {
					var pt = animatinglightnings[i];
					lightnings[pt[0]] = pt[1];
				}

				animatinglightnings = [];
			}
		}
	}

	function sqr(x) { return x * x }
	function dist2(v, w) { return sqr(v.x - w.x) + sqr(v.y - w.y) }
	function distToSegmentSquared(p, v, w) {
		var l2 = dist2(v, w);
		if (l2 == 0) return dist2(p, v);
		var t = ((p.x - v.x) * (w.x - v.x) + (p.y - v.y) * (w.y - v.y)) / l2;
		t = Math.max(0, Math.min(1, t));
		var pt = { x: v.x + t * (w.x - v.x), y: v.y + t * (w.y - v.y) };
		return {
			dist: dist2(p,{ x: v.x + t * (w.x - v.x), y: v.y + t * (w.y - v.y) }),
			pt: pt
		};
	}
	function distToSegment(p, v, w) { var dtss = distToSegmentSquared(p, v, w); return {dist: Math.sqrt(dtss.dist), pt: dtss.pt}; }

	window.setInterval(() => renderCharts(), 600000);
	renderCharts();

	function renderCharts() {
		$.ajax({
			url: "https://www.mydronemet.hu/fcapi.php?command=getdata&lat=" + race[0] + "&lon="+race[1] + "&height=100&mode=normal",
			success: (res) => {
				const selectedTimestamp = Object.keys(res.data.timeSeries)[0];
				const currentData = res.data.timeSeries[selectedTimestamp].forecast;
				const data = [];

				const keys = Object.keys(currentData.elevated.u).map(x => parseInt(x));
				const filtered_alts = keys.filter(x => x < 3500);
				const last_alt = filtered_alts[filtered_alts.length - 1];
				const alt_idx = keys.findIndex(x => x === last_alt);
				const max_height = keys[alt_idx + 1];
				const filtered_keys = keys.filter(x => x <= max_height);

				for (var i of filtered_keys) {
					data.push({
						elevation: parseInt(i),
						ws: parseFloat(currentData.elevated.wspd[i]),
						wd: parseFloat(currentData.elevated.wdir[i])
					});
				}

				if (chart1) {
					chart1.destroy();
					chart2.destroy();
				}
				chart1 = new Chart(document.getElementById('ws_chart'), {
					type: 'scatter',
					options: {
						responsive: false,
						maintainAspectRatio: false,
					},
					data: {
						labels: data.map(row => row.ws),
						datasets: [
							{
								label: 'WS',
								data: data.map(row => row.elevation)
							}
						]
					}
				});

				chart2 = new Chart(document.getElementById('wd_chart'), {
					type: 'scatter',
					options: {
						responsive: false,
						maintainAspectRatio: false,
						scales: {
							y: {
								ticks: {
									display: false
								}
							},
							x: {
								max: 360,
								min: 0
							}
						}
					},
					data: {
						labels: data.map(row => row.wd),
						datasets: [
							{
								label: 'WD',
								data: data.map(row => row.elevation),
								backgroundColor: '#FFB1C1',
							}
						]
					}
				});
			}
		})
	}

	function generateRadarFencePaths() {
		radarFencePaths = [];
		radarFenceForecasts = [];
		var pt = null;
		if (pickedPt) {
			pt = map.latLngToContainerPoint(pickedPt);
		}

		$.each(radarFences, function (x,y) {
			var path = new Path2D();

			var first = null;
			var prev = null;
			var _t = this;
			var points = [];
			$.each(this.polygon, function () {
				var point = map.latLngToContainerPoint([this[0], this[1]]);
				if (!first) {
					first = point;
				}
				points.push([point.x, point.y]);
				if (prev != null && pt) {
					var dist = distToSegment(pt, prev, point);
					if (smallestDiff.dist > dist.dist) {
						dist.pt = {
							lat: this[0],
							lng: this[1]
						};
						smallestDiff = dist
						closestFence = _t;
					}
				}
				path.lineTo(point.x, point.y);
				prev = point;
			});

			path.lineTo(first.x, first.y);

			path.fence_details = this;
			path.center = findCenter(points);
			if (!isNaN(path.center[0])) {
				var c = map.containerPointToLatLng(path.center);
				var s = parseInt(this.speed);
				var d = parseInt(this.direction);
				path.next =  map.latLngToContainerPoint([c.lat + Math.cos(d * (Math.PI / 180)) * (s / 333), c.lng + Math.sin(d * (Math.PI / 180)) * (s / 333)]);
			}
			radarFencePaths.push(path);

			radarFenceForecasts[x] = [];
			for (var i=1;i<=3;i++) {
				var path = new Path2D();
				var first = null;

				var speed = parseInt(this.speed);
				var dir = parseInt(this.direction);
				if (dir < 0) {
					dir = 360 + dir;
				}

				var yx = Math.cos(dir * (Math.PI / 180)) * (speed / 333);
				var xx = Math.sin(dir * (Math.PI / 180)) * (speed / 333);

				$.each(this.polygon, function () {
					var point = map.latLngToContainerPoint([this[0] + (yx * i), this[1] + (xx * i)]);
					if (!first) {
						first = point;
					}
					path.lineTo(point.x, point.y);
				});
				path.lineTo(first.x, first.y);
				radarFenceForecasts[x].push(path);
			}
		});

		closestIsOverlay = false;
		if (closestFence) {
			closestIsOverlay = inside(race, closestFence.polygon);
		}
	}

	function canvas_arrow(context, fromx, fromy, tox, toy) {
		var headlen = 10; // length of head in pixels
		var dx = tox - fromx;
		var dy = toy - fromy;
		var angle = Math.atan2(dy, dx);
		context.moveTo(fromx, fromy);
		context.lineTo(tox, toy);
		context.lineTo(tox - headlen * Math.cos(angle - Math.PI / 6), toy - headlen * Math.sin(angle - Math.PI / 6));
		context.moveTo(tox, toy);
		context.lineTo(tox - headlen * Math.cos(angle + Math.PI / 6), toy - headlen * Math.sin(angle + Math.PI / 6));
	}


	function findCenter(path) {
		var x = 0,
			y = 0,
			i,
			j,
			f,
			point1,
			point2;

		for (i = 0, j = path.length - 1; i < path.length; j=i,i++) {
			point1 = path[i];
			point2 = path[j];
			f = point1[0] * point2[1] - point2[0] * point1[1];
			x += (point1[0] + point2[0]) * f;
			y += (point1[1] + point2[1]) * f;
		}

		f = pathArea(path) * 6;

		return [x / f, y / f];
	}

	function pathArea(path) {
		var area = 0,
			i,
			j,
			point1,
			point2;

		for (i = 0, j = path.length - 1; i < path.length; j=i,i++) {
			point1 = path[i];
			point2 = path[j];
			area += point1[0] * point2[1];
			area -= point1[1] * point2[0];
		}
		area /= 2;

		return area;
	}

	function getKpIndex(succ) {
		$.ajax({
			url: 'https://www.mydronemet.hu/kpindex.php',
			success: function(r) {
				let data = null;
				if (r.data && r.data.serie && r.data.serie.length) {
					data = r.data.serie[r.data.serie.length - 1];
				}

				if (succ) {
					succ(data);
				}
			}
		});
	}

	function loadImage() {
		var url = "https://www.mydronemet.hu/tmp/rocketmeteo.png";
		url += "?_=" + (Math.round(new Date().getTime() / 600000) * 600);
		$chart.find("img").attr("src", url);
	}
	window.setInterval(loadImage, 600000);
	loadImage();

	function drawRadarFence(o, params) {
		console.log("redrawing radar fence canvas");

		var cnv = radarFenceCanvas;
		var ctx = radarFenceContext;
		ctx.clearRect(0, 0, cnv.width, cnv.height);
		ctx.beginPath();

		console.log(o, params);

		$.each(radarFencePaths, function (q) {
			var highlighted = false;
			if (highlighted_fence != null) {
				highlighted = true;
				if (highlighted_fence === this.fence_details) {

				} else {
					return;
				}
			}

			var line = 1;
			ctx.strokeStyle = 'white';
			ctx.fillStyle = 'rgba(255,255,255,0.3)';
			if (this.fence_details.max > 0 && this.fence_details.max < 15) {
				ctx.strokeStyle = 'blue';
				ctx.fillStyle = 'rgba(0,50,200,0.3)';
			}
			if (this.fence_details.max >= 15 && this.fence_details.max < 30) {
				ctx.strokeStyle = 'green';
				ctx.fillStyle = 'rgba(0,200,50,0.3)';
			}
			else if (this.fence_details.max >= 30 && this.fence_details.max < 50) {
				ctx.strokeStyle = 'orange';
				ctx.fillStyle = 'rgba(245,106,16,0.3)';
				line = 3;
			}
			else if (this.fence_details.max >= 50) {
				ctx.strokeStyle = 'red';
				ctx.fillStyle = 'rgba(200,50,50,0.3)';
				line = 5;
			}

			ctx.lineWidth = line;

			ctx.stroke(this);
			if (highlighted) {
				ctx.fill(this);
			}


			var speed = parseInt(this.fence_details.speed) * 2;
			var dir = parseInt(this.fence_details.direction);
			if (dir < 0) {
				dir = 360 + dir;
			}

			var z = map.getZoom();

			var y = Math.cos(dir * (Math.PI / 180)) * speed / (15 - z) * 3;
			var x = Math.sin(dir * (Math.PI / 180)) * speed / (15 - z) * 3;

			ctx.strokeStyle = 'rgba(255,255,255,1)';
			ctx.fillStyle = 'white';
			ctx.lineWidth = 2;
			if (this.next) {
				canvas_arrow(ctx, this.center[0], this.center[1], this.next.x, this.next.y);
				ctx.stroke();
			}

			if (highlighted) {
				ctx.setLineDash([2, 5]);
				$.each(radarFenceForecasts[q], function(i) {
					ctx.strokeStyle = 'rgba(255,255,255,'+(3 - i) / 2 +')';
					console.log(this);
					ctx.stroke(this);
				});
				ctx.setLineDash([]);
			}
		});

		ctx.strokeStyle = 'white';
		ctx.lineWidth = 2;
		/*if (smallestDiff.pt) {
			var point = map.latLngToContainerPoint(pickedPt);

			ctx.beginPath();
			ctx.moveTo(point.x, point.y);
			ctx.lineTo(smallestDiff.pt.x, smallestDiff.pt.y);
			ctx.stroke();

			var ll = map.containerPointToLatLng(point);
			var ll2 = map.containerPointToLatLng(smallestDiff.pt);

			var dist = Math.round(Math.sqrt(Math.pow(ll.lat - ll2.lat, 2) + Math.pow(ll.lng - ll2.lng, 2)) * 111);

			var half = [Math.round((point.x + smallestDiff.pt.x) / 2), Math.round((point.y + smallestDiff.pt.y) / 2)];
			ctx.font = '20px arial';
			ctx.fillStyle = 'white';
			var str = dist + "km";
			var w = ctx.measureText(str);
			ctx.fillText(str, half[0] - w.width / 2, half[1]);
		}*/
	}

	function fetchLightnings() {
		$.ajax({
			url: "https://www.mydronemet.hu/lightning.php?all" + (from ? "&from=" + from : ""),
			dataType: "json",
			success: function (r) {
				var d = r.data;

				var ts = getUtcTimestamp(new Date());
				var ct = 0;
				for (var i in lightnings) {
					if (ts - lightnings[i].timestamp.unix > 600000) {
						delete lightnings[i];
						ct++;
					}
				}

				console.log("Removed " + ct + " expired lightning");

				if (d === null) {
					console.info("No new lightning");
				} else {
					console.info("Found " + Object.keys(d).length + " new lightning");

					for (var i in d) {
						if (ts - d[i].timestamp.unix > 600000) {
							continue;
						}
						if (from != null) {
							animatinglightnings.push([i, d[i], 0]);
						} else {
							lightnings[i] = d[i];
						}
					}
				}

				var keys = d ? Object.keys(d) : null;
				if (keys && keys.length) {
					from = keys[keys.length - 1];
				}
				lastFetchDate = new Date();

				if (d !== null || ct > 0) {
					lightningCanvasLayer.redraw();
				}

				if (actualRadar != r.last_radar) {
					actualRadar = r.last_radar;

					radarFences = r.radar_fences;
					generateRadarFencePaths();
					radarFenceLayer.redraw();
					if (!radarLayer) {
						radarLayer = L.imageOverlay("https://www.mydronemet.hu/obs/png/" + actualRadar,
							radarImageOverlayBounds, {
								opacity: 0.7,
								zIndex: 1
							})
							.addTo(map)
					} else {
						radarLayer.setUrl("https://www.mydronemet.hu/obs/png/" + actualRadar);
					}
				}

				omsz = r.omsz.filter(x => distFromCenter(x));
				updateLastMeasuredBox();

				updateLastUpdatedText();

				if (interval === -1) {
					interval = window.setInterval(fetchLightnings, 15000);
				}
			}
		})
	}

	var winds = [];
	function updateLastMeasuredBox() {
		for (var i in winds) {
			map.removeLayer(winds[i]);
		}
		winds = [];
		$omszBox.html('');
		if (omsz) {
			for (var i in omsz) {
				var $row = createOmszRow(omsz[i]);
				$row.appendTo($omszBox);
				var marker = L.marker([omsz[i].location.lat, omsz[i].location.lon], {
					icon: L.icon({
						iconUrl: 'https://www.mydronemet.hu/wind.php?dir='+omsz[i].wind.dir+'&speed=' + omsz[i].wind.gust,
						iconAnchor:  [15, 15]
					})
				});
				marker.on("mouseover", function() {
					highlightOmszRow(this);
				}.bind(omsz[i])).on("mouseout", function() {
					highlightOmszRow(null);
				});
				winds.push(marker);
			}

			for (var i in winds) {
				winds[i].addTo(map);
			}
		}
	}

	function highlightOmszRow(item) {
		$(".trisonica li").removeClass("hovered");

		$(".trisonica .omsz-" + item.location.stid).addClass("hovered");
	}

	function distFromCenter(pt) {
		const dist = Math.sqrt(Math.pow(pt.location.lat - race[0], 2) + Math.pow(pt.location.lon - race[1], 2));
		return dist < 0.6;
	}

	function createOmszRow(data) {
		console.log(data.wind.gust);
		var $ret = $("<li>", {
			class: (data.wind.gust > 32.4 ? "strong " : (data.wind.gust > 25.2 ? "mid " : "")) + 'omsz-' + data.location.stid
		});

		var locdate = $("<div>", {
			class: 'location-date'
		}).appendTo($ret);

		$("<span>", {
			class: 'location',
			html: data.location.name
		}).appendTo(locdate);

		$("<span>", {
			class: 'date',
			html: data.date.human
		}).appendTo(locdate);
		$("<span>", {
			class: 'wind-dir',
			html: data.wind.dir
		}).appendTo($ret);

		var speed = $("<div>", {
			class: 'wind-speed'
		}).appendTo($ret);

		$("<span>", {
			class: "average",
			html: data.wind.speed
		}).appendTo(speed);

		$("<span>", {
			class: 'gust',
			html: data.wind.gust
		}).appendTo(speed);

		$("<span>", {
			class: 'temperature',
			html: data.temperature
		}).appendTo($ret);

		return $ret;
	}

	function evaluateCircleColor() {
		console.log("Evaluating circle color");
		var dist = 9999;
		var ll = pickedPt;
		if (smallestDiff && smallestDiff.pt) {
			var ll2 = smallestDiff.pt;

			var distFence =  Math.round(haversineDistance([ll.lat, ll.lng], [ll2.lat, ll2.lng], false));
			closestRadarDistance = distFence;
			closestRadarDirection = Math.round(bearing(ll.lat,  ll.lng, ll2.lat, ll2.lng));
			dist = distFence;
		}

		var closestLightning = 99999999999999;
		var closestLightningLat = 0;
		var closestLightningLon = 0;
		for (var i in lightnings) {
			var ll2 = lightnings[i].location;
			var distLightning =  Math.round(haversineDistance([ll.lat, ll.lng], [ll2.lat, ll2.lon], false));
			if (distLightning < closestLightning) {
				closestLightningLat = ll2.lat;
				closestLightningLon = ll2.lon;
				closestLightning = distLightning;

				closestLightningDistance = closestLightning;
				closestLightningDirection = Math.round(bearing(ll.lat, ll.lng, ll2.lat, ll2.lon));
			}
		}

		var max = closestFence ? closestFence.max : -1;

		$circle.removeClass("red").removeClass("orange").removeClass("yellow").removeClass("green");
		console.log(max, closestLightningDistance);
		if (!closestIsOverlay && max < 30 && ((closestLightningDistance > 50 && closestLightningDistance > -1) || (closestLightningDistance === -1))) {
			console.log(dist);
			if (max < 15 && dist < 20) {
				$circle.addClass("yellow");
			} else if (dist < 35) {
				$circle.addClass("yellow");
			} else {
				$circle.addClass("green");
			}
		}
		else {
			if (closestIsOverlay) {
				$circle.addClass("red");
			} else {
				if (dist < 20) {
					$circle.addClass("red");
				} else if (dist < 35) {
					$circle.addClass("orange");
				} else if (dist < 50) {
					$circle.addClass("yellow");
				} else {
					$circle.addClass("green");
				}
			}
		}
	}
	// Converts from degrees to radians.
	function toRadians(degrees) {
		return degrees * Math.PI / 180;
	}

// Converts from radians to degrees.
	function toDegrees(radians) {
		return radians * 180 / Math.PI;
	}


	function bearing(startLat, startLng, destLat, destLng) {
		startLat = toRadians(startLat);
		startLng = toRadians(startLng);
		destLat = toRadians(destLat);
		destLng = toRadians(destLng);

		const y = Math.sin(destLng - startLng) * Math.cos(destLat);
		const x = Math.cos(startLat) * Math.sin(destLat) -
			Math.sin(startLat) * Math.cos(destLat) * Math.cos(destLng - startLng);
		let brng = Math.atan2(y, x);
		brng = toDegrees(brng);
		return (brng + 360) % 360;
	}
	function inside(point, vs) {
		// ray-casting algorithm based on
		// https://wrf.ecse.rpi.edu/Research/Short_Notes/pnpoly.html/pnpoly.html

		var x = point[0], y = point[1];

		var inside = false;
		for (var i = 0, j = vs.length - 1; i < vs.length; j = i++) {
			var xi = vs[i][0], yi = vs[i][1];
			var xj = vs[j][0], yj = vs[j][1];

			var intersect = ((yi > y) != (yj > y))
				&& (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
			if (intersect) inside = !inside;
		}

		return inside;
	}

	function haversineDistance(coords1, coords2, isMiles) {
		function toRad(x) {
			return x * Math.PI / 180;
		}

		var lon1 = coords1[1];
		var lat1 = coords1[0];

		var lon2 = coords2[1];
		var lat2 = coords2[0];

		var R = 6371; // km

		var x1 = lat2 - lat1;
		var dLat = toRad(x1);
		var x2 = lon2 - lon1;
		var dLon = toRad(x2)
		var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
			Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) *
			Math.sin(dLon / 2) * Math.sin(dLon / 2);
		var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
		var d = R * c;

		if(isMiles) d /= 1.60934;

		return d;
	}

	function getUtcTimestamp(date) {
		var d2 = new Date(date.getUTCFullYear(), date.getUTCMonth(),
			date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());

		return d2.getTime() ;
	}

	function haversinReverse(lat, lon, dist, direction) {
		var R = 6371e3;
		var φ2 = Math.asin(  Math.sin(lat)*Math.cos(dist/R) +
								Math.cos(lat)*Math.sin(dist/R)*Math.cos(direction) );
		var λ2 = lon + Math.atan2(   Math.sin(direction)*Math.sin(dist/R)*Math.cos(lat),
									 Math.cos(dist/R)-Math.sin(lat)*Math.sin(φ2));
		return [φ2, λ2];
	}

	function updateLastUpdatedText() {
		evaluateCircleColor();

		var last = lightnings.length > 0 ? lightnings[Object.keys(lightnings).pop()] : null;
		var dateStr = lastFetchDate !== null ? lastFetchDate.toISOString().substr(0, 19).replace(/-/g, ".").replace("T", " ") : "--";

		$(".lastupdate").html("Utolsó villám: <strong>" +
			(last ? last.timestamp.human : "--") +
			"</strong> UTC<br />Összesen <strong>" +
			Object.keys(lightnings).length +
			"</strong> villám az utolsó 10 percben<br />Ebből látható: <strong>" + displayedLightnings + "</strong><br />Radar: <strong>" +
			(actualRadar ? actualRadar.substr(23, 13) + " UTC" : "--") + "</strong><br />Utoljára frissítve: <strong>" +
			dateStr +
			"</strong> UTC<br />" +
			(!closestIsOverlay ?
					"<div class='large-text'>Legközelebbi radar fence: <b>" + (closestRadarDistance > -1 ? closestRadarDistance + " km" : "-") + " / " + (closestRadarDirection > -1 ? closestRadarDirection + "°" : "-") + "</b><br/>":
					"<div class='large-text'><b style='color: #f00;'>ZÁPOR / ZIVATAR</b><br />"
			)
			 +
			"Legközelebbi villám: <b>" + (closestLightningDistance > -1 ? closestLightningDistance + " km" : "-") + " / " + (closestLightningDirection > -1 ? closestLightningDirection + "°" : "-") + "</b></div>"

		);
	}
})


